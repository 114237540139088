<template>
  <div class="flex flex-col flex-grow">
    <div
      class="flex-grow py-10 rounded w-full"
      style="
        background: linear-gradient(74.98deg, #fde1ff 0%, #e0e7ff 15.98%, #e0f9ff 32.45%, #fffae9 48.92%, #e8eaff 63.45%, #ffe3f7 77.98%, #daf1ff 92.99%, #daf1ff 92.99% );
      "
    >
      <p class="w-full text-center font-sans text-6xl">Hurray!</p>
      <p class="w-full text-center font-sans text-14">
        Hello <span class="font-bold">{{ firstName }} {{ lastName }}</span
        >, your CredPal Credit
      </p>
      <p class="w-full text-center font-sans text-14">
        Card application has been approved.
      </p>

      <register-credit-card
        :first_name="firstName"
        :last_name="lastName"
        class="w-5/10 md:w-5/10 mt-10 mx-auto"
        @mounted="emitComponentMountedEvent"
      />
    </div>

    <div
      class="
        bg-white flex flex-col gap-4 items-center justify-center
        h-150px py-5 px-10 relative rounded-b w-full
        sm:flex-row md:h-120px
      "
    >   
      <div class="absolute bg-blue-200 h-2px left-0 top-0 w-full">
        <div
          class="bg-blue-500 h-full"
          :style="{ width: progressWidth }"
        />
      </div>

      <div class="w-full flex">
        <div
          class="text-blue-400 bg-white alert-ash-soft text-left"
          style="flex: 0 0 22px; height: 22px; width: 22px"
        >
          <div class="bg-gray-200 text-center" style="border-radius: 50%">
            <span class="text-blue-500 font-bold">!</span>
          </div>
        </div>
        <div class="ml-2">
          <p class="text-12 text-left text-gray-600">
            Your current monthly credit limit is ₦{{ creditLimit | currency }}.
            Request your credit card.
          </p>
        </div>
      </div>
      <div>
        <button class="btn btn-blue" @click.prevent="next">
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  data() {
    return {
      progress: 30,
    };
  },
  computed: {
    creditLimit() {
      return this.user?.profile?.credit_limit;
    },
    progressWidth() {
      return `${this.progress}%`;
    },
  },
  methods: {
    emitComponentMountedEvent() {
      this.$emit('mounted');
    },
    next() {
      this.$emit('next');
    },
  },
};
</script>
